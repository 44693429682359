import type { Spec } from "~/model/product";
import type { ImgInfo, Page } from "./types";
import { serviceUrl, imageUrl } from "~/system/config";
import { extensionNameList } from "./const";
import type { ModuleTyp } from "./sys-config";

export function removeArrayItem<T>(list: Array<T>, item: T): boolean {
  const i = list.indexOf(item);
  if (i > -1) {
    list.splice(i, 1);
    return true;
  }
  return false;
}

// export function buildApi(api: string, params?: Record<string, string>): string {
//   // let url = `http://localhost:3000/api/${api}`;
//   let url = `${serviceUrl}/app/${api}`;
//   if (params && Object.keys(params).length > 0) {
//     return `${url}?${new URLSearchParams(params).toString()}`;
//   }
//   return url;
// }

export function buildApi(api: string, params?: URLSearchParams): string {
  // let url = `http://localhost:3000/api/${api}`;
  let url = `${serviceUrl}/app/${api}`;
  if (params) {
    return `${url}?${params.toString()}`;
  }
  return url;
}

export function buildRawImgUrl(typ: ModuleTyp, imgInfo: ImgInfo): string {
  // return `http://192.168.1.84:3000/images/device-001.png`
  if (imgInfo.fileName.trim() == "") {
    return "/image-placeholder.png"
  }
  return `${imageUrl}/${typ}/${imgInfo.fileName}` + extensionNameList[imgInfo.ext]
}

export function buildImgUrl(typ: ModuleTyp, imgInfo: ImgInfo): string {
  // return `http://192.168.1.84:3000/images/device-001.png`
  if (imgInfo.fileName.trim() == "") {
    return "/image-placeholder.png"
  }
  return `${imageUrl}/${typ}/${imgInfo.fileName}-thumb` + extensionNameList[imgInfo.ext]
}

export function buildImgUrlByKey(typ: ModuleTyp, key: number, imgList:ImgInfo[]): string {
  for (const imgInfo of imgList) {
      if (imgInfo.key == key) {
        return buildImgUrl(typ, imgInfo)
      }
  }
  return '/image-placeholder.png'
}

export function buildRawImgUrlByKey(typ:number, key: number, imgList:ImgInfo[]): string {
  for (const imgInfo of imgList) {
      if (imgInfo.key == key) {
        return buildRawImgUrl(typ, imgInfo)
      }
  }
  return '/image-placeholder.png'
}

export function scrollMaxValue() {
  const body = document.body
  const html = document.documentElement

  const documentHeight = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  )
  const windowHeight = window.innerHeight

  return documentHeight - windowHeight
}

export function normalSpecs(specList: Spec[], lang: number, selectedSpecList: number[]): string {
  let contents: string[] = []
  for (let i = 0; i < selectedSpecList.length; i++) {
    const j = selectedSpecList[i]
    if (j != -1) {
      contents.push(specList[i].itemList[j].name[lang])
    }
  }

  return contents.filter(Boolean).join('、')
}

export function calcModelIndex(specList: Spec[], selectedSpecList: number[]): number {
  let index = selectedSpecList[0];
  for (let i = 1; i < selectedSpecList.length; i++) {
      index = index * specList[i].itemList.length + selectedSpecList[i]
  }
  
  return index
}

export function toggleBodyBg(flag:boolean){
  const body = <HTMLBodyElement>document.getElementsByTagName("body")[0];
  body.style.backgroundColor = flag ? 'rgb(220, 220, 220)' : 'white'
  // if(flag){
    // body.classList.add("gray-body-bg")
  // }else{
    // body.classList.remove("gray-body-bg")
  // }
}

export function pageToParam(page: Page): Record<string, string> {
  const params: Record<string, string> = {
    skip: page.skip.toString(),
    limit: page.limit.toString(),
  };
  return params;
}

export function appendPage(params: Record<string, string>, page: Page): Record<string, string> {
  params['skip'] = page.skip.toString();
  params['limit'] = page.limit.toString();
  return params;
}

export function pageToParam2(page: Page): URLSearchParams {
  const params: Record<string, string> = {
    skip: page.skip.toString(),
    limit: page.limit.toString(),
  }
  return new URLSearchParams(params)
}

export function paramAppendPage(params: Record<string, string>, page: Page): URLSearchParams {
  params['skip'] = page.skip.toString();
  params['limit'] = page.limit.toString();
  return new URLSearchParams(params);
}

export function formatPrice(price:number):string{
  return price.toString() + "￥"
}

// export function setNextPage(page:Page, skip?:any){
//   const s = skip ? parseInt(skip as string) : 0
//   page.skip = s + page.limit
  
// }

// export function setCookie(key: string, value: string, days: number) {
//   let expires = "";
//   if (days > 0) {
//       let date = new Date();
//       date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
//       expires = "; expires=" + date.toUTCString();
//   }
//   document.cookie = key + "=" + value + expires + "; path=/";
// }

// export function getCookie(key: string): string | null {
//   const cookies = document.cookie.split(";")

//   for (var i = 0; i < cookies.length; i++) {
//       const pair = cookies[i].split("=")

//       if (key == pair[0].trim()) {
//           return decodeURIComponent(pair[1])
//       }
//   }
//   return null;
// }