import type { NuxtApp } from "#app"
import { tryRefreshSession } from './refresh-token'

type MethodType = "POST" | "GET" | "HEAD" | "PATCH" | "PUT" | "DELETE" | "CONNECT" | "OPTIONS" | "TRACE" | "post" | "get" | "head" | "patch" | "put" | "delete" | "connect" | "options" | "trace"
type BodyDataType = BodyInit | null

export type HttpContext = {
  loaded:boolean
  status:number // 0:ok 1:refresh token 2:need login
  nuxtApp:NuxtApp
}

export function newHttpContext(app: NuxtApp):HttpContext{
  return {loaded:false, status:0, nuxtApp: app}
}

export enum ResultCode {
  ok,
	notFound,
	noChanged,
	noAuthorized,
	inputInvalidated,
  cancel,
  error,
}

const resultCodeMsg:string[] = ['','Not Found', 'nothing to update','no authorized', 'input invalidated', 'cancel', 'error']

export class HttpResult {
  code: number;
  reason: number;
  msg: string;
  response: any;
 
  constructor(code: number, reason: number, msg: string, response: any) {
    this.code = code
    this.reason = reason
    this.msg = msg
    this.response = response
  }

  get isOk():boolean{
    return this.code == ResultCode.ok
  }

  toString(): string {
    if(this.code != 3 && this.code < resultCodeMsg.length){
      return resultCodeMsg[this.code]
    }
    return `Error Code: ${this.code}, Message: ${this.msg}`;
  }
}

export function newErrorResult(msg?:string):HttpResult{
  return new HttpResult(ResultCode.error, 0, msg ?? 'http error', null)
}

export function getExceptionMsg(e:unknown):string{
  if (typeof e === "string") {
    e.toUpperCase()
  } else if (e instanceof Error) {
      e.message
  }
  return 'unknow error'
}

async function fetchInvoke(ctx:HttpContext, input: RequestInfo | URL, method:string, body: BodyInit | null): Promise<HttpResult> {
  const jsonContentTypeHeader = {
    'Content-Type': 'application/json; charset=UTF-8',
  }
  const useJson = (method != "GET" && !(body instanceof FormData || body instanceof URLSearchParams))
  const requestInit:RequestInit = {
    method: method,
    credentials: 'include',
    body: body,
    headers: useJson ? jsonContentTypeHeader : undefined
  }

  try {
    const response = await fetch(input, requestInit)
    if (response.ok && response.status == 200) {
      const data = await response.json();

      if(data == null){
        return new HttpResult(0, 0, '', null)
      } else if(data.code === undefined){
        return new HttpResult(0, 0, '', data)
      } else if(data.code === ResultCode.noAuthorized){
        ctx.status = 1
        const ok = await tryRefreshSession()
        ctx.status = 0
        
        if(ok){
          return await refetchInvoke(input, requestInit)
        }else{
          return new HttpResult(ResultCode.noAuthorized, 0, '', null)
        }
      }else{
        return new HttpResult(data.code, data.reason ?? 0, data.msg ?? '', data.response ?? null)
      }
    }
    return newErrorResult()
  } catch (e) {
    return newErrorResult(getExceptionMsg(e))
  }
}

async function refetchInvoke(input: RequestInfo | URL, requestInit:RequestInit): Promise<HttpResult> {
  try {
    const response = await fetch(input, requestInit)
    if (response.ok && response.status == 200) {
      const data = await response.json();

      if(data == null){
        return new HttpResult(0, 0, '', null)
      } else if(data.code === undefined){
        return new HttpResult(0, 0, '', data)
      } else if(data.code === ResultCode.noAuthorized){
        return new HttpResult(ResultCode.noAuthorized, 0, '', null)
      }else{
        return new HttpResult(data.code, data.reason ?? 0, data.msg ?? '', data.response ?? null)
      }
    }
    return newErrorResult()
  } catch (e) {
    return newErrorResult(getExceptionMsg(e))
  }
}

async function useFetchInvoke(ctx:HttpContext, input: string, method:MethodType, body: BodyInit | null): Promise<HttpResult> {
  if(ctx.status != 0){
    return new HttpResult(ResultCode.cancel, 0, '', null)
  }

  try {
    const headers = useRequestHeaders(['cookie'])
    const {data, error} = await useFetch(input, {
      credentials: 'include',
      method: method,
      body:body,
      headers:headers
    })

    if(!error.value && data.value){
      const response = data.value as any

      if(response == null){
        return new HttpResult(0, 0, '', null)
      } else if(response.code === undefined){
        return new HttpResult(0, 0, '', response)
      } else if(response.code === ResultCode.noAuthorized){
        return new HttpResult(ResultCode.noAuthorized, 0, '', null)
      }else{
        return new HttpResult(response.code, response.reason ?? 0, response.msg ?? '', response.response ?? null)
      }
    }
    return newErrorResult()
  } catch (e) {
    return newErrorResult(getExceptionMsg(e))
  }
}

async function fetchInvokeProcess(ctx:HttpContext, input: RequestInfo | URL, method:string, body: BodyDataType): Promise<HttpResult> {
  if(ctx.status != 0){
    return new HttpResult(ResultCode.cancel, 0, '', null)
  }

  const result = await fetchInvoke(ctx, input, method, body)
  if(result.code == ResultCode.noAuthorized){
    ctx.status = 2
    await ctx.nuxtApp.runWithContext(() => navigateTo('/login'))
  }

  return result
}

async function useFetchInvokeProcess(ctx:HttpContext, input: string, method:MethodType, body: BodyDataType): Promise<HttpResult> {
  if(ctx.status != 0){
    return new HttpResult(ResultCode.cancel, 0, '', null)
  }

  const result = await useFetchInvoke(ctx, input, method, body)
  if(result.code == ResultCode.noAuthorized){
    ctx.status = 2
    await ctx.nuxtApp.runWithContext(() => navigateTo('/refresh-token?back='+useRequestURL().pathname))
  }

  return result
}

export async function getFetch(ctx:HttpContext, input: RequestInfo | URL): Promise<HttpResult>{
  return fetchInvokeProcess(ctx, input, 'GET', null)
}

export async function postFetch(ctx:HttpContext, input: RequestInfo | URL, body: BodyDataType = null): Promise<HttpResult>{
  return fetchInvokeProcess(ctx, input, 'POST', body)
}

export async function putFetch(ctx:HttpContext, input: RequestInfo | URL, body: BodyDataType = null): Promise<HttpResult>{
  return fetchInvokeProcess(ctx, input, 'PUT', body)
}

export async function deleteFetch(ctx:HttpContext, input: RequestInfo | URL, body: BodyDataType = null): Promise<HttpResult>{
  return fetchInvokeProcess(ctx, input, 'DELETE', body)
}

export async function postFetchJson(ctx:HttpContext, input: RequestInfo | URL, data:Object): Promise<HttpResult>{
  return fetchInvokeProcess(ctx, input, 'POST', JSON.stringify(data))
}

export async function putFetchJson(ctx:HttpContext, input: RequestInfo | URL, data:Object): Promise<HttpResult>{
  return fetchInvokeProcess(ctx, input, 'PUT', JSON.stringify(data))
}

export async function deleteFetchJson(ctx:HttpContext, input: RequestInfo | URL, data:Object): Promise<HttpResult>{
  return fetchInvokeProcess(ctx, input, 'DELETE', JSON.stringify(data))
}

export async function getUseFetch(ctx:HttpContext, input: string): Promise<HttpResult>{
  return useFetchInvokeProcess(ctx, input, 'GET', null)
}

export async function postUseFetch(ctx:HttpContext, input: string, body: BodyDataType = null): Promise<HttpResult>{
  return useFetchInvokeProcess(ctx, input, 'POST', body)
}

export async function postUseFetchJson(ctx:HttpContext, input: string, data:Object): Promise<HttpResult>{
  return useFetchInvokeProcess(ctx, input, 'POST', JSON.stringify(data))
}